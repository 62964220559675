import React from "react"
import styled from "@emotion/styled"
import Layout from "../../components/global/layout"
import TextCard from "../../components/block/TextCard"
import { urls } from "../../data/urls"

const Grid = styled.div`
  padding: 0 16px;
`
const CustomLink = styled.a`
  width: 100%;
  display: block;
  text-align: left;
  color: ${props => props.theme.shade[100]};
  & + * {
    margin-top: 8px;
  }
`

export default function Outro() {
  return (
    <Layout hideHamburger>
      <Grid>
        <TextCard
          title="Further Support"
          text={
            <>
              <CustomLink href="https://www.brook.org.uk/">
                Do I need to see a doctor?
              </CustomLink>
              <CustomLink href="https://www.brook.org.uk/">
                Vulva and vagina FAQs
              </CustomLink>
              <CustomLink href="https://www.brook.org.uk/">
                Love your vulva
              </CustomLink>
              <CustomLink href="https://www.brook.org.uk/">
                Get to know your vulva
              </CustomLink>
              <CustomLink href="https://www.brook.org.uk/">
                Discharge
              </CustomLink>
              <CustomLink href="https://www.brook.org.uk/">
                Watch 6 women drawing their vulvas at a life drawing class with
                a difference
              </CustomLink>
              <CustomLink href="https://www.brook.org.uk/">
                Do I have an STI?
              </CustomLink>
              <CustomLink href="https://www.brook.org.uk/">
                Painful sex
              </CustomLink>
              <CustomLink href="https://www.brook.org.uk/">
                Free STI home testing kits in England
              </CustomLink>
              <CustomLink href="https://www.brook.org.uk/your-life/thrush-isabels-story/">
                Thrush: Isabel's Story
              </CustomLink>
              <CustomLink href="https://www.brook.org.uk/your-life/thrush-marlenas-story/">
                Thrush: Marlena's Story
              </CustomLink>
            </>
          }
          textColor={props => props.theme.shade[100]}
          backgroundColor={props => props.theme.rose}
          link={urls.VH.NEXT}
          buttonText="Next"
        />
      </Grid>
    </Layout>
  )
}
